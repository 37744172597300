<template>
  <div class="">
    <el-dialog title="奖品详情"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="70%">
      <div class="content">
        <el-table :data="tableData"
                  v-if="type === 'ENVELOPE'"
                  v-loading="loadding"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column label="派发状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'NORMAL'">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已派发
              </span>
              <span v-else-if="scope.row.status === 'FAIL'">
                <el-badge is-dot
                          type="danger"
                          class="mr-15"></el-badge>派发失败
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>派发中
              </span>
            </template>
          </el-table-column>
          <el-table-column label="是否到账"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.status === 'NORMAL'  ? '是' :'否'}}
            </template>
          </el-table-column>
          <el-table-column label="失败原因"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.status === 'FAIL' ? scope.row.err_msg:''}}
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type='text'
                         v-permission="['ADMIN']"
                         v-if="scope.row.status === 'FAIL'"
                         @click="againReceive(scope.row)"
                         size="mini">补发</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  v-loading="loadding"
                  v-if="type === 'COUPON'"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column label="派发状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'NORMAL'">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已派发
              </span>
              <span v-else-if="scope.row.status === 'FAIL'">
                <el-badge is-dot
                          type="danger"
                          class="mr-15"></el-badge>派发失败
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>派发中
              </span>
            </template>
          </el-table-column>
          <el-table-column label="批次号">
            <template slot-scope="scope">
              {{scope.row.coupon_stock_id}}
            </template>
          </el-table-column>
          <el-table-column label="代金券ID">
            <template slot-scope="scope">
              {{scope.row.prize_coupon_id}}
            </template>
          </el-table-column>
          <el-table-column label="失败原因"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.status === 'FAIL' ? scope.row.err_msg:''}}
            </template>
          </el-table-column>
          <el-table-column label="核销状态">
            <template slot-scope="scope">
              <span v-if="scope.row.verified_at">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已核销
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>未核销
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="verified_at"
                           label="核销时间"
                           show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  v-loading="loadding"
                  v-if="type === 'KIND'"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column label="派发状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'NORMAL'">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已派发
              </span>
              <span v-else-if="scope.row.status === 'FAIL'">
                <el-badge is-dot
                          type="danger"
                          class="mr-15"></el-badge>派发失败
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>派发中
              </span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip
                           label="收件人">
            <template slot-scope="scope">
              {{scope.row.address_name}}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip
                           label="联系电话">
            <template slot-scope="scope">
              {{scope.row.address_tel}}
            </template>
          </el-table-column>
          <el-table-column prop="address_address"
                           show-overflow-tooltip
                           label="收件地址">
            <template slot-scope="scope">
              {{scope.row.address_address}}
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  v-loading="loadding"
                  v-if="type === 'VERIFY'"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column label="派发状态"
                           show-overflow-tooltip>
            <span>
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>已派发
            </span>
          </el-table-column>
          <el-table-column label="核销状态">
            <template slot-scope="scope">
              <span v-if="scope.row.verified_at">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已核销
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>未核销
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="verified_at"
                           show-overflow-tooltip
                           label="核销时间">
          </el-table-column>
          <el-table-column prop="verify_marketer_name"
                           show-overflow-tooltip
                           label="核销员工">
          </el-table-column>
          <el-table-column prop="verify_marketer_tel"
                           show-overflow-tooltip
                           label="核销员工手机号">
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  v-loading="loadding"
                  v-if="type === 'CARD_KEY'"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column prop="card_key"
                           show-overflow-tooltip
                           label="兑换码">
          </el-table-column>
        </el-table>
        <el-table :data="tableData"
                  v-loading="loadding"
                  v-if="type === 'REFUEL'"
                  style="width: 100%">
          <el-table-column prop="prize_name"
                           show-overflow-tooltip
                           label="奖品名称">
          </el-table-column>
          <el-table-column prop="card_key"
                           show-overflow-tooltip
                           label="兑换手机号">
            <template slot-scope="scope">
              {{scope.row.refuel_data ? scope.row.refuel_data.tel :'暂未兑换'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getMemberLuckyRecordDetail, againReceive } from '@/api/market'
export default {
  name: '',
  data () {
    return {
      dialogVisible: false,
      prize: null,
      type: '',
      tableData: [],
      loadding: false,
      activity_id: ''
    }
  },
  filters: {
    status (val) {
      const statusTypes = {
        NORMAL: '已领取',
        PENNDING: '派发中',
        FAIL: '派发失败'
      }
      return statusTypes[val]
    }
  },
  components: {
  },
  methods: {
    async againReceive (item) {
      this.$confirm('确认补发？', {
        type: 'warning'
      }).then(async () => {
        const res = await againReceive({
          activity_id: this.activity_id,
          log_id: item.id
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.dialogVisible = false
          this.$emit('on-success')
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    },
    showPrizeDetail (scope, acId) {
      this.dialogVisible = true
      this.type = scope.row.prize_type
      this.activity_id = acId
      this.$nextTick(() => {
        this.tableData = []
        this.loadding = true
        this.getMemberLuckyRecordDetail(scope.row.id)
        this.$forceUpdate()
      })
    },
    async getMemberLuckyRecordDetail (id) {
      const res = await getMemberLuckyRecordDetail({
        id
      })
      if (res.meta.code === 0) {
        this.tableData = res.data.lists
      } else {
        this.$message.error(res.meta.msg)
      }
      this.loadding = false
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
