<template>
  <div>
    <table-page dataName="lists"
                ref="table"
                :search="search"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="getMemberLuckyRecord">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>

      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="user_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="中奖时间">
      </el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="会员状态">
        <template slot-scope="scope">
          <span v-if="scope.row.member_status">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>新会员
          </span>
          <span v-else>
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>老会员
          </span>

        </template>
      </el-table-column>
      <el-table-column prop="areas_name"
                       show-overflow-tooltip
                       label="奖品类型">
        <template slot-scope="scope">
          <span>{{scope.row.prize_type | prizeName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="marketer_tel"
                       show-overflow-tooltip
                       label="派发员工手机号码">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="prizeDetail(scope)">查看奖品详情</el-button>
        </template>
      </el-table-column>

    </table-page>
    <RecordDetail ref='record'
                  @on-success="$refs.table.fetch()" />
  </div>

</template>

<script>
import RecordDetail from './components/RecordDetail'
import FileExport from '@/components/FileExport'
import TablePage from '@/components/TablePage'
import { getMemberLuckyRecord } from '@/api/market'
export default {
  name: 'MemberLuckyRecord',
  data () {
    return {
      exportParams: null,
      getMemberLuckyRecord,
      dialogVisible: false,
      searchParams: {},
      exportUrl: '',
      search: [
        {
          type: 'input',
          name: '姓名',
          placeholder: '单行输入',
          key: 'name',
          value: ''
        }, {
          type: 'input',
          name: '手机号码',
          placeholder: '单行输入',
          key: 'user_tel',
          value: ''
        }, {
          type: 'input',
          name: '派发员工手机号码',
          placeholder: '单行输入',
          key: 'marketer_tel',
          value: ''
        }, {
          type: 'input',
          name: 'openid',
          placeholder: '单行输入',
          key: 'openid',
          value: ''
        },
        {
          type: 'select',
          key: 'prize_type',
          name: '奖品类型',
          list: [
            {
              value: 'ENVELOPE',
              label: '红包'
            },
            {
              value: 'COUPON',
              label: '代金券'
            },
            {
              value: 'KIND',
              label: '邮寄实物'
            },
            {
              value: 'VERIFY',
              label: '网点核销'
            },
            {
              value: 'CARD_KEY',
              label: '卡密'
            },
            {
              value: 'REFUEL',
              label: '加油券'
            }
          ]
        }
      ]
    }
  },
  components: {
    TablePage,
    RecordDetail,
    FileExport
  },
  filters: {
    prizeName (val) {
      const prizeTypes = {
        ENVELOPE: '红包',
        COUPON: '代金券',
        KIND: '邮寄实物',
        VERIFY: '网点核销',
        CARD_KEY: '卡密',
        REFUEL: '加油券'
      }
      return prizeTypes[val]
    }
  },
  methods: {
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData (url) {
      window.open(url, '_blank')
    },
    prizeDetail (scope) {
      this.$refs.record.showPrizeDetail(scope, this.$route.query.id)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
